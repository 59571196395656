import dev from "./dev.json";
import qa from "./qa.json";
import prod from "./prod.json";

interface IConfig {
  auth0: {
    domain: string;
    clientId: string;
  };
}

export function getConfig(): IConfig {
  switch (process.env.REACT_APP_BUILD_ENV) {
    case "dev":
      return dev;
    case "qa":
      return qa;
    case "prod":
      return prod;
    default:
      return dev;
  }
}
