import "./App.css";
import logo from "./dataops-original.svg";
import LoginTest from "./LoginTest";

function App() {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <img src={logo} alt="logo" className="mb-12" />

          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-6">
            Single Sign On Verification Tool
          </h2>
          <LoginTest />
        </div>
      </div>
    </div>
  );
}

export default App;
