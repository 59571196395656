import { useAuth0 } from "@auth0/auth0-react";

function LoginTest() {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();

  const learnMoreUrl =
    "https://docs.dataops.live/docs/administration/improved-account-security/";

  if (isLoading) {
    return (
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-info motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    );
  }

  if (isAuthenticated && user) {
    return (
      <>
        <p className="mx-auto max-w-xl text-lg leading-8 text-gray-600">
          Hi {user.name}. It looks like your Single Sign On configuration is
          working correctly!
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            className="rounded-md bg-dataops-primary-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-dataops-primary-blue-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dataops-primary-blue"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            Log Out
          </button>
          <a
            href={learnMoreUrl}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Learn more <span aria-hidden="true">→</span>
          </a>
        </div>
      </>
    );
  } else {
    return (
      <>
        <p className="mx-auto max-w-xl text-lg leading-8 text-gray-600">
        Click "Test Sign On" to verify that your Single Sign On configuration is working correctly.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            className="rounded-md bg-dataops-primary-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-dataops-primary-blue-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dataops-primary-blue"
            onClick={() => loginWithRedirect()}
          >
            Test Sign On
          </button>
          <a
            href={learnMoreUrl}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Learn more <span aria-hidden="true">→</span>
          </a>
        </div>
      </>
    );
  }
}

export default LoginTest;
